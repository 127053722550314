import React from "react";
import Iframe from "react-iframe";
import { RedocStandalone } from "redoc";

import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  ListItemText,
  SwipeableDrawer,
  ListItem,
  Divider,
  List,
} from "@material-ui/core";
import { Menu as MenuIcon } from "@material-ui/icons";

import "./App.css";
import { makeStyles } from "@material-ui/core/styles";

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
  },
  drawer: {
    [theme.breakpoints.up("sm")]: {
      width: drawerWidth,
      flexShrink: 0,
    },
  },
  appBar: {
    [theme.breakpoints.up("sm")]: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
    },
  },
  menuButton: {
    marginRight: theme.spacing(2),
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  drawerPaper: {
    width: drawerWidth,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
}));

const SPECS = {
  DESIGN: "./specs/design-spec.json",
  BUILD: "./specs/build-spec.json",
  TEST: "./specs/test-spec.json",
  DISCOVER: "./specs/evolve-spec.json",
  CLI: "./specs/cli.html",
};

const INITIAL_SELECTED_TAB = "DESIGN";

function isSpecHtml(specPath) {
  var extensionPattern = /\.[0-9a-z]+$/i;
  const match = specPath.match(extensionPattern);

  return match && match[0] === ".html";
}

function Documentation(props) {
  const spec = props.spec;

  const component = isSpecHtml(spec) ? (
    <Iframe url={spec} position="absolute" width="100%" height="100%" />
  ) : (
    <RedocStandalone
      specUrl={spec}
      options={{
        nativeScrollbars: false,
        theme: { colors: { primary: { main: "#003554" } } },
        hideDownloadButton: true,
        noAutoAuth: true,
      }}
    />
  );

  console.log("component:", component);

  return component;
}

function App() {
  const [module, setModule] = React.useState(INITIAL_SELECTED_TAB);
  const [sidebarOpen, toggleSidebar] = React.useState(false);
  let spec = SPECS[module];

  const url = new URL(window.location.href);
  var baseurl = url.searchParams.get("baseurl");
  if (baseurl) {
    spec = `https://cors-anywhere.herokuapp.com/${baseurl}${module.toLowerCase()}/cli-api/docs/json`;
  }
  console.log(`spec:`,spec)
  const classes = useStyles();

  // const handleChange = (newValue) => {
  //   setModule(newValue);
  // };

  return (
    <div>
      <AppBar position="sticky">
        <Toolbar style={{ display: "flex", justifyContent: "space-between" }}>
          <IconButton
            onClick={() => {
              toggleSidebar(!sidebarOpen);
            }}
            edge="start"
            color="inherit"
            aria-label="select-module-menu"
          >
            <MenuIcon />
            &nbsp;
            <Typography variant="h6">{module} API</Typography>
          </IconButton>

          <a href="https://teselagen.com">
            <img src="@teselagen_text.svg" alt="logo" className="logo" />
          </a>
          {/* <Button color="inherit">Login</Button> */}
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        classes={{
          paper: classes.drawerPaper,
        }}
        anchor={"left"}
        open={sidebarOpen}
        onClose={() => {
          toggleSidebar(false);
        }}
        onOpen={() => {
          toggleSidebar(true);
        }}
      >
        <div className={classes.toolbar} />
        <ListItem>
          <ListItemText primary="Select Module:"></ListItemText>
        </ListItem>
        <Divider />
        <List>
          <ListItem
            button
            selected={module === "DESIGN"}
            className="tab"
            onClick={() => {
              setModule("DESIGN");
              toggleSidebar(false);
            }}
          >
            <ListItemText primary="DESIGN"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={module === "BUILD"}
            className="tab"
            onClick={() => {
              setModule("BUILD");
              toggleSidebar(false);
            }}
          >
            <ListItemText primary="BUILD"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={module === "TEST"}
            className="tab"
            onClick={() => {
              setModule("TEST");
              toggleSidebar(false);
            }}
          >
            <ListItemText primary="TEST"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={module === "DISCOVER"}
            className="tab"
            onClick={() => {
              setModule("DISCOVER");
              toggleSidebar(false);
            }}
          >
            <ListItemText primary="DISCOVER"></ListItemText>
          </ListItem>
          <ListItem
            button
            selected={module === "CLI"}
            className="tab"
            onClick={() => {
              setModule("CLI");
            }}
          >
            <ListItemText primary="CLI"></ListItemText>
          </ListItem>
        </List>
      </SwipeableDrawer>
      <Documentation spec={spec} />
    </div>
  );
}

export default App;
